.container--custom{
  max-width: 1070px;
}
$red: #9f261d;
.o-07{
  opacity: 0.7;
}
.heading{
  &--big{
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &--red{
    color: $red;
  }
  &--white{
    color: #fff;
  }
  &--medium{
    font-size: 1.8rem;
    font-weight: 700;
  }
}
.recommendation{
  &__text{
    font-size: 1.6rem;
    color: #fff;
    text-align: center;
    &--medium{
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
  &__banner{
    height: 36vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: flex;
    justify-content: center;
    padding-top: 10vw;
    @media screen and (max-width: 991px){
      height: 70vw;
    }
    @media screen and (max-width: 768px){
      height: 100vw;
      padding-top: 10rem;
    }
  }
  &__share{
    position: relative;
    overflow: hidden;
    padding: 15rem 0;
    // background-image: url('../images/logo_ariadna_polecenia.png');
    background-color: $red;
    @media screen and (max-width: 1024px){
      padding: 11rem 0;
    }
    @media screen and (max-width: 767px){
      padding: 5rem 0;
    }
    .share__background{
      position: absolute;
      opacity: 0.6;
      bottom: -42%;
      left: 13%;
      @media screen and (max-width: 767px){
        bottom: -40%;
        left: 15px;
      }
      @media screen and (max-width: 991px){
        bottom: -60%;
        left: 15px;
      }
    }
    &-box{
      .share__link{
        font-size: 1.8rem;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 7rem;
        border: 1px solid rgba(255, 255, 255, 0.2);
        max-width: 57rem;
        margin: 0 auto;
        padding-left: 3rem;
        margin-top: 4rem;
        margin-bottom: 5rem;
        @media screen and (max-width: 767px){
          padding-left: 1.5rem;
        }
        .o-07{
          @media screen and (max-width: 767px){
            width: calc(100% - 8rem);
            overflow-x: hidden;
          }
        }
        .share--icon{
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 7rem;
          width: 7rem;
          background: #FFF;
          cursor: pointer;
          transition: all .5s;
          &:hover{
            opacity: 0.8;
          }
        }
      }
    }
  }
  &__social{
    max-width: 48.5rem;
    margin: 0 auto;
    text-align: center;
    .social__box{
      justify-content: center;
      margin-top: 3.3rem;
      a{
        color: #FFF;
        font-size: 1.6rem;
        &:hover{
          color: darken(#FFF, 30%);
          svg{
            fill: darken(#FFF, 30%);
          }
        }
      }
    }
    .social__icon{
      margin: 0 auto;
      width: 4.8rem;
      display: block;
      svg{
        fill: #fff;
        transition: all .5s ease;
      }
    }
  }
}
.how{
  padding: 15rem 0;
  @media screen and (max-width: 1024px){
    padding: 11rem 0;
  }
  @media screen and (max-width: 767px){
    padding: 5rem 0;
  }
  &__list{
    display: flex;
    list-style: none;
    justify-content: space-between;
    @media screen and (max-width: 767px){
      flex-direction: column;
    }
  }
  &__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px){
      margin-bottom: 3rem;
    }
  }
  &__number{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: 800;
    color: #FFF;
    text-transform: uppercase;
    background: $red;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
  }
  &__step{
    font-size: 1.8rem;
    color: #000000;
    margin-top: 3rem;
    @media screen and (max-width: 767px){
      margin-top: 1.5rem;
    }
    p{
      opacity: 0.7;
      font-size: 1.6rem;
      margin-top: 1.5rem;
      @media screen and (max-width: 767px){
        margin-top: 1rem;
      }
    }
  }
}
.bread-steps ul .line--custom{
  width: 17%;
  margin-top: 3.5rem;
  background: none;
  border-top: 1px  dashed #cfcfcf;
  @media screen and (max-width: 767px){
    display: none;
  }
  @media screen and (max-width: 991px){
    margin: 3.5rem 0 0 0;
  }
}

.refs{
  padding-bottom: 20rem;
  @media screen and (max-width: 991px){
    padding-bottom: 10rem;
  }
  @media screen and (max-width: 767px){
    padding-bottom: 5rem;
  }
  &-progress{
    margin-top: 13rem;
    padding: 0 3.4rem;
    @media screen and (max-width: 767px){
      padding: 0;
    }
    &__bar{
      height: 1px;
      width: 100%;
      background-color: #dddddd;
      position: relative;
    }
    &__level{
      position: absolute;
      height: 3px;
      left: 0;
      top: -1px;
      width: 35%;
      background-color: $red;
    }
    &__pin{
      position: absolute;
      right: -1.5rem;
      top: -5.3rem;
      height: 3rem;
      width: 3rem;
      background: $red;
      color: #fff;
      font-size: 1.6rem;
      font-weight: 700;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &::before{
        content: '';
        height: 2.3rem;
        width: 1px;
        background-color: #e3e3e3;
        position: absolute;
        bottom: -2.3rem;
        left: auto;
      }
    }
    &__points{
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
      font-weight: 700;
      font-size: 1.6rem;
      .points-count{
        color: #FFF;
        background-color: $red;
        padding: 6px 17px;
        border-radius: 5px;
        margin-right: 5px;
      }
    }
  }
  &-table{
    padding: 0 3.4rem;
    margin-top: 6.5rem;
    @media screen and (max-width: 767px){
      padding: 0;
    }
    &__heading{
      display: flex;
      height: 6rem;
      font-weight: 700;
      color: #FFF;
      background: #000000;
      align-items: center;
      border-radius: 5px;
    }
    &__row{
      display: flex;
      height: 7.2rem;
      border-bottom: 1px solid #dddddd;
      align-items: center;
    }
    .table__item{
      &--narrow{
        width: 17.5%;
        display: flex;
        justify-content: center;
      }
      &--red{
        color: $red;
        font-weight: 700;
      }
      &--wide{
        width: 54.5%;
      }
    }
  }
}
